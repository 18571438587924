import React from "react";
import "./App.css";

function App() {

  return (
    <div className="App">
      <div style={{position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden", marginTop: '6.5%', zIndex: 1}}>
        <iframe
          src="https://player.stornaway.io/embed/db80877a"
          frameBorder="0"
          allowFullScreen
          allowfullscreen
          allow="accelerometer; gyroscope;autoplay;"
          style={{width: "100%", height: "100%", position: "absolute", top: 0, left: 0}}
        ></iframe>
        <script
          src="https://studio.stornaway.io/embed/v1/player.js"
          defer
        ></script>
      </div>
    </div>
  );
}

export default App;
